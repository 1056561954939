import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { LoadingService } from 'src/app/core';

@Component({
    selector: 'loader',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.scss'],
    standalone: false
})
export class LoadingScreenComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  loadingSubscription: Subscription | undefined;

  constructor(
    private _loading: LoadingService
  ) {
  }

  ngOnInit() {
    this.loadingSubscription = this._loading.loadingStatus.subscribe((value:any) => {
      this.loading = value;
    });
  }

  ngOnDestroy() {
    this.loadingSubscription?.unsubscribe();
  }

}