import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ThemeService } from 'src/app/core/services/theme.service';

@Component({
  selector: 'app-theme-selector',
  templateUrl: './theme-selector.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class ThemeSelectorComponent implements OnInit {
  themes = [
    { value: 'auto', label:'system' },
    { value: 'light',label:'light' },
    { value: 'dark',label:'dark' }
  ];

  selectedTheme: 'light' | 'dark' | 'auto' = 'auto';

  constructor(private themeService: ThemeService) {}

  ngOnInit() {
    this.selectedTheme = this.themeService.getCurrentTheme();
  }

  changeTheme() {
    this.themeService.applyTheme(this.selectedTheme);
  }
}