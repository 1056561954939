import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalData } from 'src/app/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  standalone: false
})
export class SettingsComponent implements OnInit {
  activeTab:any;
  tabs = [
    { "id": 1, "icon": "settings", "name": "general", "route": "dashboard", "isActive": true },
    { "id": 2, "icon": "notifications", "name": "notifications", "route": "notifications", "isActive": false },
    { "id": 3, "icon": "loyalty", "name": "subscription", "route": "dashboard", "isActive": false }
  ]

  constructor(
    public dialogRef: MatDialogRef<SettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData) {
  }

  ngOnInit() {
    this.selectedControl(this.tabs[0])
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  selectedControl(tab:any) {
    setTimeout(() => {
      this.activeTab = tab;
      this.tabs.forEach(tab => tab.isActive = (tab === this.activeTab));
    },100);
  }
}
