import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
  standalone:false
})
export class SubscriptionComponent implements OnInit {
  subscription = {
    plan: 'Parklab Enterprise',
    renewalDate: 'Limitless',
    features: [
      'Ücretsiz plandaki tüm özellikler',
      'Mesajlaşma, dosya yükleme, gelişmiş veri analizi ve görsel oluşturma',
      'Standart ve gelişmiş ses modu',
      'Gelişmiş araştırma ve akıl yürütme modellerine erişim',
      "Özel GPT'ler oluşturma ve kullanma",
      'Sora video oluşturma',
      'Yeni özellikleri test etme fırsatları'
    ]
  };

  manageSubscription() {
    // Buraya yönlendirme kodu eklenebilir
  }

  constructor() { }

  ngOnInit() {
  }

}
