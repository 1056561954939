import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.css'],
    standalone: false
})
export class UserCardComponent implements OnInit {
  userInfo:any;
  role:any;
  constructor() { }

  ngOnInit() {   
    /*this._utility.user.subscribe((_:any)=>{
      this.userInfo=_;
    });*/
  }

}
