import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
  standalone:false
})
export class NotificationsComponent implements OnInit {
  notifications = [
    { value: 'pushNotification', label:'pushNotification' },
    { value: 'emailNotification',label:'emailNotification' }
  ];
  constructor() { }

  ngOnInit() {
  }

  
  changeTheme() {
  }

}
