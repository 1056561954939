<div class="apps-container" [class.apps-is-mobile]="mobileQuery.matches">
  <div class="apps-toolbar toolbar-bg">
    <div style="display: flex;align-items: center;margin-top: .5rem;margin-bottom: 1rem;" (click)="snav.toggle()">
      <img src="../../../assets/icons/icon-512x512.png" style="cursor: pointer;"  alt="parklab" height="36px">
      <a class="apps-app-name toolbar-tx" style="font-size: 16px;font-weight: bolder;cursor: pointer;">PARKLAB</a>
    </div>
    <span class="spacer"></span>
    <div class="header-image" [matMenuTriggerFor]="menu"></div>
    <mat-menu #menu="matMenu">
      <app-user-card></app-user-card>
      <a mat-menu-item  (click)="settings()">
        <mat-icon>settings</mat-icon>
        <span>{{'settings' | translate}}</span>
      </a>
      <a mat-menu-item  routerLink="/apps/support">
        <mat-icon>help</mat-icon>
        <span>{{'help' | translate}}</span>
      </a>
      <mat-divider></mat-divider>
      <a mat-menu-item routerLink="/login"  (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>{{'logout' | translate}}</span>
      </a>
    </mat-menu>
  </div>
  <mat-drawer-container class="apps-sidenav-container">
    <mat-drawer style="width: 120px;" #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="false"
    [fixedInViewport]="mobileQuery.matches">
        <mat-nav-list>
          @for (tab of tabs; track tab) {
            <a mat-list-item 
            style="cursor: pointer;margin-bottom: 0.2rem;margin-top: 0.2rem;" 
            matTooltipPosition="right" 
            (click)="selectedControl()"  
            [matTooltip]="tab.name | translate" 
            [routerLink]="[tab.route]" 
            [activated]="tab.isActive">
              <div style="display: flex;flex-direction: column;align-items: center;">
                <mat-icon mat-list-icon>{{tab.icon}}</mat-icon>
                <span mat-line style="font-size:10px"> {{tab.name | translate}}</span>
              </div>
            </a>
          }
        </mat-nav-list>
  </mat-drawer>
    <mat-drawer-content style="overflow-x:hidden;">
      <loader></loader>
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>