import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.css'],
  standalone:false
})
export class LanguageSelectorComponent implements OnInit {

  selectedLang: string = 'en-US';
  langs=[
    {viewValue:'English',value:'en-US'},
    {viewValue:'Türkçe',value:'tr-TR'},
    {viewValue:'Deutsch',value:'de-DE'}
  ]
  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.selectedLang = localStorage.getItem('lang') || 'en-US';
  }

  changeLanguage(lang: string) {
    this.selectedLang = lang;
    localStorage.setItem('lang', lang);
    this.translateService.language(lang);
  }
}
