<div style="display: flex;align-items: center;justify-content: space-between;padding-left: 10px;padding-right: 10px;">
    <h1 mat-dialog-title style="font-size: 21px;">{{'settings' | translate}}</h1>
    <button mat-mini-fab (click)="onNoClick()">
      <mat-icon (click)="onNoClick()">cancel</mat-icon>
    </button>
</div>
  <div mat-dialog-content>
    <mat-drawer-container class="settings-container">
      <mat-drawer style="width: 140px;"  mode="side"  opened>
            <mat-nav-list>
              @for (tab of tabs; track tab) {
                <a mat-list-item 
                style="cursor: pointer;margin-bottom: 0.2rem;margin-top: 0.2rem;" 
                matTooltipPosition="right" 
                (click)="selectedControl(tab)"  
                [matTooltip]="tab.name | translate" 
                [routerLink]="[tab.route]" 
                [activated]="tab.isActive">
                  <div style="display: flex;align-items: center;">
                    <mat-icon mat-list-icon>{{tab.icon}}</mat-icon>
                    <span mat-line style="font-size:10px;margin-left:.5rem"> {{tab.name | translate}}</span>
                  </div>
                </a>
              }
            </mat-nav-list>
    </mat-drawer>
      <mat-drawer-content style="padding-left:10px;padding-right: 10px;">
        <div *ngIf="activeTab.id==1">
          <div class="section">
            <h3>{{'theme' | translate}}</h3>
            <app-theme-selector></app-theme-selector>
          </div>
          <mat-divider></mat-divider>
          <div class="section">
            <h3>{{'language' | translate}}</h3>
            <app-language-selector></app-language-selector>
          </div>
        </div>
        <div *ngIf="activeTab.id==2">
          <app-notifications></app-notifications>
        </div>
        <div *ngIf="activeTab.id==3">
          <app-subscription></app-subscription>
        </div>
    

      </mat-drawer-content>
    </mat-drawer-container>
</div>
